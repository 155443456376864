var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"elevation":"1"}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-regular title"},[_vm._v(" Face Liveness Requests ")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function () { return this$1.fetchLivenessRequests(); }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-reload")]),_c('div',{staticClass:"reload-text"},[_vm._v("Reload")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"search":_vm.filterText,"headers":_vm.livenessRequestHeaders,"items":_vm.livenessRequests,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"disable-sort":"","item-key":"requestId","id":"liveness-table","footer-props":_vm.footerProps},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":function (item) { return _vm.$router.push(_vm.livenessRequestUrl(item)); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-2 lighten-2 d-flex align-center"},[_c('v-text-field',{attrs:{"value":_vm.query,"solo":"","clearable":"","hide-details":"","placeholder":"Search","prepend-inner-icon":"mdi-magnify"},on:{"click:clear":_vm.searchPressed,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchPressed.apply(null, arguments)},"focusout":_vm.searchPressed}})],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.confidence",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(100 * item.confidence))+"% ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","to":_vm.livenessRequestUrl(item)}},[_vm._v(" More ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }